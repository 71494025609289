import React from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import useHandleFormDuplicate from "./useHandleFormDuplicate";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const gridStyle = { height: 75, padding: "0px" };

const DuplicateDialog = (props) => {

  const { open, data, paramNumToUse, handleConfirm, handleClose } = props;

  const dispatch = useDispatch();

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleFormDuplicate(data, paramNumToUse, handleConfirm, openValidationError);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Duplicar`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* nombre */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Nombre del parámetro"}
              value={formData.name}
              onChange={(e) => setFormValue(e.target.value, "name")}
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Número de orden del parámetro */}
            <TextField
              disabled
              fullWidth
              margin={"none"}
              label={'Número de orden del parámetro'}
              type="number"
              value={formData.paramNum}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;

import NAME from "./constants";

export const getDocRows = store => store[NAME].docRows;
export const getDocsFailed = store => store[NAME].docsFailed;
export const getDocsFailedDelete = store => store[NAME].docsFailedDelete;

export const getLocale = store => store[NAME].locale;
export const getDateFormat = store => store[NAME].dateFormat;

export const loading = store => store[NAME].gettingDocs || store[NAME].sendingMail || store[NAME].removingDocs;

export const downloading = store => store[NAME].downloadingDocs;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getData,
  getConcepts,
  getFunctions,
  getTypes,
  getGroups,
  getExecutionList,
  getPathList,
  getTableList,
  getSysTableList,
  getUserMenuList,
  getQueryList,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { deleteSpecialProcessDefinitionAction, getSpecialProcessDefinitionAction } from "../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useSpecialProcessDefinition = () => {

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selected, setSelected] = useState(null); //seleccionado para editar o borrar

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const concepts = useSelector(getConcepts);
  const functions = useSelector(getFunctions);
  const types = useSelector(getTypes);
  const groups = useSelector(getGroups);
  const executionList = useSelector(getExecutionList);
  const pathList = useSelector(getPathList);
  const tableList = useSelector(getTableList);
  const sysTableList = useSelector(getSysTableList);
  const userMenuList = useSelector(getUserMenuList);
  const queryList = useSelector(getQueryList);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getSpecialProcessDefinitionAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (documents[0].inUse) {
      dispatch(openSnackbarAction({ msg: 'No puede eliminar un registro que se encuentre en uso', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar el proceso especial?",
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenCreateDialog = () => {
    setSelected(null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelected(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelected(null);
  }

  const handleDelete = (document) => {
    dispatch(deleteSpecialProcessDefinitionAction({ code: document.code, name: document.name }));
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    selected,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
  }

  return {
    data,
    concepts,
    functions,
    types,
    groups,
    executionList,
    pathList,
    tableList,
    sysTableList,
    userMenuList,
    queryList,
    isLoading,
    state,
    handlers,
  }
}

export default useSpecialProcessDefinition;
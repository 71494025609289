import React from "react";
import { useSelector } from "react-redux";
import { getIsExportingGrid } from "src/app/selectors";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import SetParamDialog from "@icarius-common/setParamDialog";
import useSpecialProcessExecution from "./hooks/useSpecialProcessExecution";
import ListIcon from '@material-ui/icons/List';
import useSpecialProcessQuery from "./hooks/useSpecialProcessQuery";

const SpecialProcessExecution = () => {

  const isExportinGrid = useSelector(getIsExportingGrid);

  const {
    specialProcessExecutionData,
    specialProcessExecutionFunctions,
  } = useSpecialProcessExecution();

  const {
    specialProcessQueryData,
    specialProcessQueryFunctions,
  } = useSpecialProcessQuery();

  const generateThirdPartyFileItem = (gridRef) =>
    <CustomIconButton
      title={'Ejecutar'}
      onClick={() => specialProcessExecutionFunctions.handleValidateAndAskThirdPartyGenerationProcess(gridRef)}
      type={"execute"}
    />

  const queryButton = (gridRef) => (
    <CustomIconButton
      title={'Consulta del proceso especial'}
      onClick={() => specialProcessQueryFunctions.handleGetSpecialProcessQuery(gridRef)}
    >
      <ListIcon />
    </CustomIconButton>
  )

  return (
    <CommonPage
      columnDefPage={paths.specialProcessExecution}
      title={'Ejecución de procesos especiales'}
      gridTitle={'Ejecución de procesos especiales'}
      menuItems={[generateThirdPartyFileItem, queryButton]}
      rowData={specialProcessExecutionData.data}
      dateFormat={specialProcessExecutionData.dateFormat}
      isLoading={isExportinGrid || specialProcessExecutionData.isLoading || specialProcessQueryData.isLoading}
      hasExpand
      hasHelp
    >
      {
        specialProcessExecutionData.paramDialogIsOpen &&
        <SetParamDialog
          open={specialProcessExecutionData.paramDialogIsOpen}
          params={specialProcessExecutionData.paramsToUse}
          handleClose={specialProcessExecutionFunctions.handleCloseParamDialog}
          handleAgree={specialProcessExecutionFunctions.generateThirdPartyFile}
        />
      }
    </CommonPage>
  );
}

export default SpecialProcessExecution;

import NAME from "./constants";

export const GET_ROWS = NAME + "/GET_ROWS";
export const GET_ROWS_FULFILLED = NAME + "/GET_ROWS_FULFILLED";
export const GET_ROWS_REJECTED = NAME + "/GET_ROWS_REJECTED";

export const CLEAR_STATE = NAME + "/CLEAR_STATE";

export const EXECUTE = NAME + "/EXECUTE";
export const EXECUTE_FULFILLED = NAME + "/EXECUTE_FULFILLED";
export const EXECUTE_REJECTED = NAME + "/EXECUTE_REJECTED";
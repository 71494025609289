import { getSigners, addSigner, getManagersEmployersBySocietyAPI, deleteSigner, updateSigner, getActiveTypeDocsToSign } from "@icarius-connection/api";
import {
  GET_SIGNERS,
  GET_SIGNERS_FULFILLED,
  GET_SIGNERS_REJECTED,
  ADD_SIGNER,
  ADD_SIGNER_FULFILLED,
  ADD_SIGNER_REJECTED,
  DELETE_REQUEST,
  DELETE_REQUEST_REJECTED,
  DELETE_REQUEST_FULFILLED,
  EDIT_SIGNER_REJECTED, EDIT_SIGNER, EDIT_SIGNER_FULFILLED,
  GET_ACTIVE_TYPE_DOCS_TO_SIGN, GET_ACTIVE_TYPE_DOCS_TO_SIGN_REJECTED, GET_ACTIVE_TYPE_DOCS_TO_SIGN_FULFILLED,
  GET_MANAGERS_EMPLOYERS, GET_MANAGERS_EMPLOYERS_FULFILLED, GET_MANAGERS_EMPLOYERS_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

export const getSignersFromServer = () => async (dispatch) => {
  dispatch({ type: GET_SIGNERS });
  try {
    let response = await getSigners();

    let signers = response.data && response.data.signers;
    let date_format = response.data && response.data.date_format;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: GET_SIGNERS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: GET_SIGNERS_FULFILLED,
      payload: {
        signers: signers,
        dateFormat: date_format,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_SIGNERS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const addSignerAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: ADD_SIGNER });

    let response = await addSigner(data);

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: ADD_SIGNER_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("signerAlreadyExists"), severity: "error" }));
      return;
    }

    dispatch(openSnackbarAction({ msg: getLocalizedString("signerCorrectlyAdded"), severity: "success" }));

    dispatch({
      type: ADD_SIGNER_FULFILLED, payload: {
        signer: response.data.addSigner
      }
    });
    return response;
  } catch (e) {
    if (e.response.data.error === "SIGNER_ALREADY_EXIST") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("signerAlreadyExists"), severity: "error" }));
    } else if (e.response.data.error === "INVALID_DOCS") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("invalidDocs"), severity: "error" }));
    } else if (e.response.data.error === "INVALID_USER") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("invalidUser"), severity: "error" }));
    } else if (e.response.data.fields.length && e.response.data.fields.length > 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("verifyDateTime"), severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: ADD_SIGNER_REJECTED });
  }
};

export const editSignerAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_SIGNER });

    let response = await updateSigner(data);

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: EDIT_SIGNER_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("signerAlreadyExists"), severity: "error" }));
      return;
    }

    dispatch(openSnackbarAction({ msg: getLocalizedString("signerCorrectlyUpdated"), severity: "success" }));
    dispatch({
      type: EDIT_SIGNER_FULFILLED, payload: {
        signer: response.data.editSigner
      }
    });
    return response;
  } catch (e) {
    if (e.response.data.error === "SIGNER_ALREADY_EXIST") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("signerAlreadyExists"), severity: "error" }));
    } else if (e.response.data.error === "INVALID_DOCS") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("invalidDocs"), severity: "error" }));
    } else if (e.response.data.error === "INVALID_USER") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("invalidUser"), severity: "error" }));
    } else if (e.response.data.fields.length && e.response.data.fields.length > 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("verifyDateTime"), severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: EDIT_SIGNER_REJECTED });
  }
};
export const getActiveTypeDocsToSignAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ACTIVE_TYPE_DOCS_TO_SIGN });

    let response = await getActiveTypeDocsToSign();

    if (response.data.status !== "OK") {
      dispatch({ type: GET_ACTIVE_TYPE_DOCS_TO_SIGN_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("errorGettingDocsType"), severity: "error" }));
      return response;
    }

    dispatch({ type: GET_ACTIVE_TYPE_DOCS_TO_SIGN_FULFILLED, payload: { docs: response.data.typeDocsToSign } });
    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    dispatch({ type: GET_ACTIVE_TYPE_DOCS_TO_SIGN_REJECTED });
  }
};

export const getManagersEmployersFromServer = () => async (dispatch) => {
  dispatch({ type: GET_MANAGERS_EMPLOYERS });
  try {
    let response = await getManagersEmployersBySocietyAPI();

    let managersEmployers = response.data && response.data.usuarios;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: GET_MANAGERS_EMPLOYERS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: GET_MANAGERS_EMPLOYERS_FULFILLED,
      payload: {
        managersEmployers: managersEmployers,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_MANAGERS_EMPLOYERS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const deleteSignerAction = data => async (dispatch) => {
  const signerCode = { idSigner: data.userCode };
  try {
    dispatch({ type: DELETE_REQUEST });

    let response = await deleteSigner(signerCode);

    if (response.data.status === "OK") {
      dispatch({ type: DELETE_REQUEST_FULFILLED, payload: { code: data.userCode } });

      dispatch(openSnackbarAction({ msg: getLocalizedString("signerCorrectlyDeleted"), severity: "success" }));
      return response;
    }

    dispatch({ type: DELETE_REQUEST_REJECTED });
    return response;
  } catch (e) {
    if (e.response.data?.fields?.length > 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("verifyDateTime"), severity: "error" }));
    } else {
      if (e.response.data.error === "INVALID_USER") {
        dispatch(openSnackbarAction({ msg: getLocalizedErrorString("invalidUser"), severity: "error" }));
      } else {
        if (e.response.data.error === "EXISTS_SIGNED_DOCS") {
          dispatch(openSnackbarAction({ msg: "No puede borrar usuario que posea documentos firmados", severity: "error" }));
        } else {
          dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
        }
      }
    }
    dispatch({ type: DELETE_REQUEST_REJECTED });
  }
};

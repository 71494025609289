import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Typography,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";

const DocsFailedDialog = (props) => {

  const { handleClose, data, open, isDelete } = props;

  const handleCloseDialog = () => {
    handleClose();
  };

  const getMotive = message => {
    switch (message) {
      case "APPROVATION_NOT_REQUIRED":
        return getLocalizedString("signedDocumentsNotRequired");
      case "INVALID_DATA":
        return getLocalizedString("invalidDataDeleteDocs");
      case "STATUS_INVALID":
        return getLocalizedString("invalidStatusDeleteDocs");
      case "INVALID_SIGNER":
        return getLocalizedString("invalidSignerDeleteDocs");
      case "DOCUMENT_INVALID_WF":
        return getLocalizedString("errorAwaitingColaboratorConfirmationDeleteDocs");
      default:
        return getLocalizedString("signedDocumentsInvalidDocument")
    }
  }

  return (
    <Dialog
      open={open}
      PaperComponent={PaperDraggable}
      scroll={"paper"}
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("atention")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleCloseDialog} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Typography
          style={{ paddingBottom: 15 }}
          className="whiteText"
          variant="h6"
          gutterBottom>
          {getLocalizedString(isDelete ? "signedDocumentsDeleteErrorTitle" : "signedDocumentsMailErrorTitle")}:
        </Typography>
        <Grid container direction="row">
          {
            data.map((item, index) => {
              return <Grid item container key={index} direction="column" style={{ paddingBottom: index === (data.length - 1) ? 0 : 15 }}>
                <Typography className="whiteText" variant="subtitle1" gutterBottom>
                  {`${getLocalizedString("signedDocumentsDocumento")}: ${item.docName}`}
                </Typography>
                <Typography className="whiteText" variant="subtitle1" gutterBottom>
                  {`${getLocalizedString("signedDocumentsDestinatario")}: ${item.destinataryName}`}
                </Typography>
                <Typography className="whiteText" variant="subtitle1" gutterBottom>
                  {`${getLocalizedString("signedDocumentsMotivo")}: ${getMotive(item.error)}`}
                </Typography>
              </Grid>
            })
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction
          onClick={handleCloseDialog}
          isAccept
          text={getLocalizedString("agree")}
        />
      </DialogActions>
    </Dialog>
  );
}
export default DocsFailedDialog;

import {
  GET_DOC_ROWS,
  GET_DOC_ROWS_FULFILLED,
  GET_DOC_ROWS_REJECTED,
  DOWNLOAD_DOC,
  DOWNLOAD_DOC_FULFILLED,
  DOWNLOAD_DOC_REJECTED,
  DELETE_DOC,
  DELETE_DOC_FULFILLED,
  DELETE_DOC_REJECTED,
  SEND_MAIL,
  SEND_MAIL_FULFILLED,
  SEND_MAIL_REJECTED,
  CLEAR_STATE
} from "./actionTypes";

const initialState = {
  docRows: [],
  locale: "es",
  dateFormat: "yyyy/mm/dd",
  gettingDocs: false,
  downloadingDocs: false,
  sendingMail: false,
  removingDocs: false,
  docsFailed: [],
  docsFailedDelete: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STATE:
      return initialState;
    case GET_DOC_ROWS:
      return { ...state, gettingDocs: true };
    case GET_DOC_ROWS_FULFILLED:
      return {
        ...state,
        gettingDocs: false,
        docRows: action.payload.docs || [],
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
      };
    case GET_DOC_ROWS_REJECTED:
      return { ...state, gettingDocs: false };

    case DOWNLOAD_DOC:
      return { ...state, downloadingDocs: true };
    case DOWNLOAD_DOC_FULFILLED:
      return {
        ...state,
        downloadingDocs: false,
      };
    case DOWNLOAD_DOC_REJECTED:
      return { ...state, downloadingDocs: false };

    case DELETE_DOC:
      return { ...state, removingDocs: true, docsFailedDelete: [] };
    case DELETE_DOC_FULFILLED:
      return {
        ...state,
        removingDocs: false,
        docsFailedDelete: action.payload.docsFailed
      };
    case DELETE_DOC_REJECTED:
      return { ...state, removingDocs: false, docsFailedDelete: [] };
    case SEND_MAIL:
      return { ...state, sendingMail: true, docsFailed: [] };
    case SEND_MAIL_FULFILLED:
      return { ...state, sendingMail: false, docsFailed: action.payload.docsFailed };
    case SEND_MAIL_REJECTED:
      return { ...state, sendingMail: false, docsFailed: [] };
    default:
      return state;
  }
}

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { executeSpecialProcessAction, getSpecialProcessExecutionAction } from "../../actions";
import { getRows, getDateFormat, getLoading } from "../../selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useSpecialProcessExecution = () => {

  const dispatch = useDispatch();

  const data = useSelector(getRows);
  const dateFormat = useSelector(getDateFormat);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getSpecialProcessExecutionAction());
  }, [dispatch])

  const [paramsToUse, setParamsToUse] = useState(null);
  const [paramDialogIsOpen, setParamDialogIsOpen] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(null);

  const handleValidateAndAskThirdPartyGenerationProcess = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedOneRow"), severity: "warning" }));
      return;
    }
    
    const process = selectedRows[0];
    setSelectedProcess(process);

    dispatch(openDialogAction({
      title: "Atención",
      msg: `¿Está seguro que desea ejecutar el proceso especial: ${process['Nombre del proceso']}?`,
      onConfirm: () => handleStartThirdPartyGenerationProcess(process),
      onCancel: () => handleCloseParamDialog(),
    }));

  }

  const handleStartThirdPartyGenerationProcess = (process) => {
    if (process?.parameters?.length) {
      //abrir modal de setparam
      setParamDialogIsOpen(true);
      setParamsToUse(process.parameters);
      return;
    }

    // si no tiene parametros
    generateThirdPartyFile(null, process);
  }

  const handleCloseParamDialog = () => {
    setParamDialogIsOpen(false);
    setParamsToUse(null);
    setSelectedProcess(null);
  }

  const generateThirdPartyFile = (param, process) => {
    const processToUse = process || selectedProcess;

    dispatch(executeSpecialProcessAction({
      process: processToUse["Código del proceso"],
      parameters: param || [],
    })).then(() => {
      handleCloseParamDialog();
    })
  }

  const specialProcessExecutionData = {
    data,
    isLoading,
    dateFormat,
    selectedProcess,
    paramsToUse,
    paramDialogIsOpen,
  }

  const specialProcessExecutionFunctions = {
    handleValidateAndAskThirdPartyGenerationProcess,
    generateThirdPartyFile,
    handleCloseParamDialog,
  }

  return {
    specialProcessExecutionData,
    specialProcessExecutionFunctions,
  }
}

export default useSpecialProcessExecution;

import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.socialBlacklist],
        config: {
            headerName: "Código de usuario bloqueado",
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.socialBlacklist],
        config:
        {
            headerName: "Nombre de usuario bloqueado",
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.socialBlacklist],
        config: {
            headerName: 'Fecha de bloqueo',
            field: "date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.socialBlacklist],
        config: {
            headerName: "Código de usuario que bloqueó",
            field: "blockerCode",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.socialBlacklist],
        config:
        {
            headerName: "Nombre de usuario que bloqueó",
            field: "blockerName",
            filter: "agTextColumnFilter",
        }
    },
]
import * as actionTypes from "./actionTypes";

const initialState = {
  rows: [],
  dateFormat: "yyyy/mm/dd",
  isLoading: false,
  paramTypes: [],
  listTypes: [],
  requiredTypes: [],
  alertActive: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_STATE:
      return initialState;

    case actionTypes.GET_ROWS:
    case actionTypes.CREATE:
    case actionTypes.MODIFY:
    case actionTypes.DELETE:
    case actionTypes.DUPLICATE:
      return { ...state, isLoading: true };

    case actionTypes.GET_ROWS_FULFILLED:
    case actionTypes.CREATE_FULFILLED:
    case actionTypes.MODIFY_FULFILLED:
    case actionTypes.DELETE_FULFILLED:
    case actionTypes.DUPLICATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
        paramTypes: action.payload.paramTypes,
        listTypes: action.payload.listTypes,
        requiredTypes: action.payload.requiredTypes,
        dateFormat: action.payload.dateFormat,
        alertActive: action.payload.alertActive,
      };

    case actionTypes.GET_ROWS_REJECTED:
    case actionTypes.CREATE_REJECTED:
    case actionTypes.MODIFY_REJECTED:
    case actionTypes.DELETE_REJECTED:
    case actionTypes.DUPLICATE_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}

import {
  GET_DOC_ROWS,
  GET_DOC_ROWS_FULFILLED,
  GET_DOC_ROWS_REJECTED,
  DOWNLOAD_DOC,
  DOWNLOAD_DOC_FULFILLED,
  DOWNLOAD_DOC_REJECTED,
  SEND_MAIL,
  SEND_MAIL_FULFILLED,
  SEND_MAIL_REJECTED,
  DELETE_DOC,
  DELETE_DOC_FULFILLED,
  DELETE_DOC_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getSignedDocuments,
  downloadSignedDocuments,
  deleteSignedDocuments,
  sendSignedDocumentsMailAPI
} from "@icarius-connection/api";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

export const getDocumentsSignedFromServer = () => async (dispatch) => {
  dispatch({ type: GET_DOC_ROWS });
  try {
    let response = await getSignedDocuments();

    let docs = response.data && response.data.docs;
    let dateFormat = response.data.date_format;
    let locale = response.data.currency_localization;

    dispatch({
      type: GET_DOC_ROWS_FULFILLED,
      payload: {
        docs, locale, dateFormat,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_DOC_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const downloadDocumentsFromServer = (docsArray) => async (dispatch) => {
  dispatch({ type: DOWNLOAD_DOC });
  try {
    let response = await downloadSignedDocuments({ docs: docsArray });

    if (response.status !== 200) {
      dispatch({ type: DOWNLOAD_DOC_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: DOWNLOAD_DOC_FULFILLED,
    });

    return response;
  } catch (e) {
    dispatch({ type: DOWNLOAD_DOC_REJECTED });
    if (e.response.status === 422) {
      dispatch(openSnackbarAction({ msg: "No existen los documentos seleccionados", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
  }
};

export const deleteDocumentsFromServer = (docsArray, motive, pin) => async (dispatch) => {
  dispatch({ type: DELETE_DOC });
  try {
    let response = await deleteSignedDocuments({ docs: docsArray, obs: motive, password: pin });

    if (response.status !== 200) {
      dispatch({ type: DELETE_DOC_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    let docsFailed = [];

    if (response.data.docs) {
      response.data.docs.forEach((item) => {
        if (item.deleted === false) {
          docsFailed.push(item)
        }
      })
    }

    if (response.data.docs.length !== docsFailed.length) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("docsHaveBeenDeleted").replace("{numberOfDocuments}", response.data.docs.length), severity: "success" }))
    }

    dispatch({
      type: DELETE_DOC_FULFILLED,
      payload: {
        docsFailed: docsFailed,
      }
    });

    return response;
  } catch (e) {
    dispatch({ type: DELETE_DOC_REJECTED });
    if (e.response.status === 422) {
      dispatch(openSnackbarAction({ msg: "No existen los documentos seleccionados", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
  }
};

export const sendMailAction = (data) => async (dispatch) => {
  dispatch({ type: SEND_MAIL });
  try {
    let response = await sendSignedDocumentsMailAPI({ docs: data });

    if (response.status !== 200) {
      dispatch({ type: SEND_MAIL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    let docsFailed = [];

    if (response.data.reminderSended) {
      response.data.reminderSended.forEach((item) => {
        if (item.sended === false) {
          docsFailed.push(item)
        }
      })
    }

    if (response.data.reminderSended.length !== docsFailed.length) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("mailsHaveBeenSent"), severity: "success" }))
    }

    dispatch({
      type: SEND_MAIL_FULFILLED,
      payload: {
        docsFailed: docsFailed,
      }
    });

    return response;
  } catch (e) {
    dispatch({ type: SEND_MAIL_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
import NAME from "./constants";

export const getLoading = store => store[NAME].isLoading;
export const getData = store => store[NAME].data;
export const getConcepts = store => store[NAME].concepts;
export const getFunctions = store => store[NAME].functions;
export const getTypes = store => store[NAME].types;
export const getGroups = store => store[NAME].groups;
export const getExecutionList = store => store[NAME].executionList;
export const getPathList = store => store[NAME].pathList;
export const getTableList = store => store[NAME].tableList;
export const getSysTableList = store => store[NAME].sysTableList;
export const getUserMenuList = store => store[NAME].userMenuList;
export const getQueryList = store => store[NAME].queryList;

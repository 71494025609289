import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.specialProcessExecution],
        config: {
            headerName: 'Código del proceso',
            field: "Código del proceso",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.specialProcessExecution],
        config: {
            headerName: 'Grupo',
            field: "group",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessExecution],
        config: {
            headerName: 'Nombre del proceso',
            field: "Nombre del proceso",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessExecution],
        config: {
            headerName: 'Función al iniciar',
            field: "Función al iniciar",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessExecution],
        config: {
            headerName: 'Concepto para procesar',
            field: "Concepto para procesar",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessExecution],
        config: {
            headerName: 'Función al finalizar',
            field: "Función al finalizar",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessExecution],
        config: {
            headerName: 'Modo de ejecución',
            field: "Modo de ejecución",
            filter: "agSetColumnFilter",
        }
    },
]
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSignersFromServer, getActiveTypeDocsToSignAction, getManagersEmployersFromServer } from "../actions";
import { getSigners, getManagersEmployers, loading, getDocs } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";

const DigitalSignature = () => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();

  const signers = useSelector(getSigners);
  const loadingRows = useSelector(loading);
  const color = useSelector(getAppColor);
  const docs = useSelector(getDocs);
  const managersEmployers = useSelector(getManagersEmployers);

  useEffect(() => {
    dispatch(getSignersFromServer());
    dispatch(getManagersEmployersFromServer());
    dispatch(getActiveTypeDocsToSignAction());
  }, [dispatch])

  const getDataIsEmpty = () => {
    return !signers || signers.length === 0;
  }

  return (
    <ABMLayout
      title={'Firmantes'}
      isLoading={loadingRows}
      showCreateButton={!createIsOpen}
      createButtonTitle={'Nuevo firmante'}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showNoData={getDataIsEmpty()}
      noDataTitle={'No se hay firmante registrados'}
      noDataCreateTitle={'Nuevo firmante'}
      noDataOnClick={() => setCreateIsOpen(true)}
      audioName={"Firmantes"}
      audioTitle={"Firmantes"}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            color={color}
            data={null}
            docs={docs}
            managersEmployers={managersEmployers}
            closeNew={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        signers?.map((item) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.userCode}>
              <DataItem
                data={item}
                color={color}
                docs={docs}
                managersEmployers={managersEmployers}
              />
            </Grid>
          )
        })
      }
    </ABMLayout>
  )
}

export default DigitalSignature;
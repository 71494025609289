import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.specialProcessDefinition],
        config: {
            headerName: 'Código de proceso',
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.specialProcessDefinition],
        config: {
            headerName: 'Nombre de proceso',
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessDefinition],
        config: {
            headerName: 'Grupo',
            field: "groupString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessDefinition],
        config: {
            headerName: 'Activo',
            field: "activeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessDefinition],
        config: {
            headerName: 'Tipo',
            field: "typeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessDefinition],
        config: {
            headerName: 'Función al iniciar',
            field: "functionStartString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessDefinition],
        config: {
            headerName: 'Concepto para procesar',
            field: "concept",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessDefinition],
        config: {
            headerName: 'Función al finalizar',
            field: "functionEndString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessDefinition],
        config: {
            headerName: 'Modo de ejecución',
            field: "executionString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.specialProcessDefinition],
        config: {
            headerName: 'Grilla, tabla, menú o consulta asociada',
            field: "gridString",
            filter: "agSetColumnFilter",
        }
    },
]
import * as actionTypes from "./actionTypes";

const initialState = {
  signers: [],
  loading: false,
  dateFormat: "yyyy/mm/dd",
  docs: [],
  managersEmployers: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.NEW_SIGNER: {
      let newSignerList = [];
      if (state.signers.length > 0) {
        newSignerList = [...state.signers];
        // Si ya hay uno nuevo, no creo otro
        if (newSignerList[0].code === "" && newSignerList[0].workplace_id === "" && newSignerList[0].workplace_name === "") {
          return { ...state, signers: newSignerList };
        }
      }

      let newSigner = {
        code: "",
        workplace_id: "",
        workplace_name: "",
      };

      newSignerList.unshift(newSigner);
      return { ...state, signers: newSignerList };
    }
    case actionTypes.DELETE_SIGNER_NOT_CREATED: {
      const arrResultante = state.signers.filter((item, index) => index !== action.payload);
      return {
        ...state,
        signers: arrResultante,
        messageModalIsOpen: false,
      };
    }
    case actionTypes.GET_SIGNERS:
      return { ...state, loading: true };
    case actionTypes.GET_SIGNERS_FULFILLED:
      return {
        ...state,
        loading: false,
        signers: action.payload.signers,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.GET_SIGNERS_REJECTED:
      return { ...state, loading: false };
    case actionTypes.ADD_SIGNER_FULFILLED: {
      const newSignerList = [...state.signers];
      newSignerList.unshift(action.payload.signer)

      return { ...state, signers: newSignerList, loading: false };
    }
    case actionTypes.ADD_SIGNER:
      return { ...state, loading: true };
    case actionTypes.ADD_SIGNER_REJECTED:
      return { ...state, loading: false };
    case actionTypes.EDIT_SIGNER:
      return { ...state, loading: true };
    case actionTypes.EDIT_SIGNER_REJECTED:
      return { ...state, loading: false };
    case actionTypes.EDIT_SIGNER_FULFILLED: {
      let newSignerList = [...state.signers];
      const signerIndex = state.signers.findIndex(signer => signer.userCode === action.payload.signer.userCode);
      let modifiedSigner = action.payload.signer;
      newSignerList[signerIndex] = modifiedSigner;

      return { ...state, signers: newSignerList, loading: false };
    }
    case actionTypes.DELETE_REQUEST: {
      return { ...state, loading: true };
    }
    case actionTypes.DELETE_REQUEST_REJECTED: {
      return { ...state, loading: false };
    }
    case actionTypes.DELETE_REQUEST_FULFILLED: {
      const arrResultante = state.signers.filter(item => item.userCode !== action.payload.code);
      return { ...state, signers: arrResultante, loading: false };
    }
    case actionTypes.GET_ACTIVE_TYPE_DOCS_TO_SIGN: return { ...state, loading: true }
    case actionTypes.GET_ACTIVE_TYPE_DOCS_TO_SIGN_FULFILLED: return { ...state, loading: false, docs: action.payload.docs }
    case actionTypes.GET_ACTIVE_TYPE_DOCS_TO_SIGN_REJECTED: return { ...state, loading: false }
    case actionTypes.GET_MANAGERS_EMPLOYERS: return { ...state, loading: true }
    case actionTypes.GET_MANAGERS_EMPLOYERS_FULFILLED: return { ...state, loading: false, managersEmployers: action.payload.managersEmployers }
    case actionTypes.GET_MANAGERS_EMPLOYERS_REJECTED: return { ...state, loading: false }
    default:
      return state;
  }
}

import React from "react";
import { useDispatch, connect } from "react-redux";
import { addSignerAction, editSignerAction } from "../../actions";
import { Typography, MenuItem, Grid } from "@material-ui/core";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { createDateFromDDMMYYYY, formatTime, createDateTimeFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import { Select, DatePicker, TimePicker, TextField } from "mui-rff";
import { Form } from "react-final-form";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const gridStyle = { height: 75, padding: "0px 10px" };

const SignerContent = (props) => {

  const { docs, managersEmployers, signer, isForUpdate, handleClose } = props;

  const shouldApplyMediaQuery = useMediaQuery('(max-width:600px)');

  const dispatch = useDispatch();

  const onChange = (code) => {
    const legalName = managersEmployers.find(subItem => subItem.code === code);
    if (legalName) {
      window.setFormValue("legalName", legalName.name);
    }
    window.setFormValue("user", code);
  }

  const dataIsComplete = values => {
    if (
      ((values.hourEnd === "" || values.hourEnd === null) && !(values.dateEnd === "" || values.dateEnd === null))
      || (!(values.hourEnd === "" || values.hourEnd === null) && (values.dateEnd === "" || values.dateEnd === null))
    ) {
      return false;
    }

    if (
      ((values.hourIni === "" || values.hourIni === null) && !(values.dateIni === "" || values.dateIni === null))
      || (!(values.hourIni === "" || values.hourIni === null) && (values.dateIni === "" || values.dateIni === null))
    ) {
      return false;
    }
    if (values.documents.length > 0 && values.user && values.legalName)
      return true;
    return false;
  };

  const datesAreCorrect = values => {
    let dateExp, dateIni;
    let resultado = false;

    if (values.hourEnd === "" || values.hourEnd === null) {
      return true;
    } else if ((values.hourIni === "" || values.hourIni === null) && values.hourEnd) {
      dateIni = new Date();
      dateExp = values.dateEnd;
      dateExp.setHours(values.hourEnd.getHours());
    } else {
      dateExp = values.dateEnd;
      dateExp.setHours(values.hourEnd.getHours());
      dateIni = values.dateIni;
      dateIni.setHours(values.hourIni.getHours());
    }

    resultado = dateExp > dateIni;

    if (resultado === false) {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("verifyDateTime"), severity: "error" }));
    }

    return resultado;
  }

  const validateAndCreate = values => {

    if (dataIsComplete(values) && datesAreCorrect(values)) {
      // Tengo que limpiar el typedocs
      const typeDocs = values.documents.filter((item) => docs.find(x => x.id === item));
      const signerToSend = {
        "idSigner": values.user,
        "legalName": values.legalName,
        "typeDocs": typeDocs.join(", "),
        "fechaIni": values.dateIni === null || values.dateIni === "" ? null : formatDateYYYYMMDD(values.dateIni),
        "tiempoIni": values.hourIni === null || values.hourIni === "" ? null : formatTime(values.hourIni),
        "fechaExp": values.dateEnd === null || values.dateEnd === "" ? null : formatDateYYYYMMDD(values.dateEnd),
        "tiempoExp": values.hourEnd === null || values.hourEnd === "" ? null : formatTime(values.hourEnd),
      };

      if (isForUpdate) {
        updateSigner(signerToSend);
      } else {
        createSigner(signerToSend);
      }
    }
  };

  const updateSigner = signer => {
    dispatch(editSignerAction(signer));
    handleClose();
  };

  const createSigner = signer => {
    dispatch(addSignerAction(signer));
    handleClose();
  };

  let initialValues;

  if (signer && Object.keys(signer).length > 0) {

    initialValues = {
      user: signer.userCode,
      legalName: signer.legalName,
      documents: signer.typeDocs.map(doc => doc.id),
      dateEnd: signer.dateExp === "31/12/9999" ? "" : createDateFromDDMMYYYY(signer.dateExp),
      hourEnd: signer.dateExp === "31/12/9999" && signer.timeExp === "23:59" ? "" : createDateTimeFromDDMMYYYY(signer.dateExp, signer.timeExp),
      dateIni: createDateFromDDMMYYYY(signer.dateIni),
      hourIni: createDateTimeFromDDMMYYYY(signer.dateIni, signer.timeIni),
    };

  } else {
    initialValues = {
      user: "",
      legalName: "",
      documents: [],
      dateEnd: "",
      hourEnd: "",
      dateIni: "",
      hourIni: "",
    };
  }

  return (
    <Form
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      initialValues={initialValues}
      onSubmit={validateAndCreate}
      render={({ handleSubmit, form, submitting, pristine, values, active }) => {
        window.setFormValue = form.mutators.setValue;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
              <Grid container item xs={12}>
                <Grid container item alignItems="center" xs={12}>
                  <Typography
                    className={!dataIsComplete(values) ? "errorColor" : "whiteText"}
                    variant="caption"
                    gutterBottom
                  >
                    {getLocalizedString("fieldRequeriment")}
                  </Typography>
                </Grid>
                <Grid container item alignItems="center" sm={12} style={gridStyle}>
                  <Select
                    label={getLocalizedString("user")}
                    name={"user"}
                    onChange={e => onChange(e.target.value)}
                    formControlProps={{
                      margin: "normal",
                    }}>
                    {
                      managersEmployers.map((subItem, index) => (
                        <MenuItem
                          className={"whiteText"}
                          key={index}
                          value={subItem.code}
                        >
                          {subItem.name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, marginTop: 10 }}>
                  <TextField
                    label={getLocalizedString("nameSurnameLegalSigner")}
                    fullWidth
                    name={"legalName"}
                    margin={"none"}
                    onInput={e => {
                      e.target.value = e.target.value.toString().slice(0, 84);
                    }}
                    helperText={getLocalizedString("captionLegalName")}
                  />
                </Grid>
                <Grid container item alignItems="center" sm={12} md={6} style={gridStyle}>
                  <Select
                    label={getLocalizedString("authorizedDocuments")}
                    multiple
                    name={"documents"}
                    formControlProps={{
                      margin: "normal",
                    }}
                  >
                    {
                      docs.map((subItem, index) => (
                        <MenuItem
                          className={"whiteText"}
                          key={index}
                          value={subItem.id}
                        >
                          {subItem.value}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid container item alignItems="center" sm={12} md={6} style={{ minHeight: 75, padding: "0px 10px" }}>
                  <Grid container item xs={6} sm={3} style={{ paddingRight: 15 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <DatePicker
                        label={getLocalizedString("dateFrom")}
                        clearable
                        clearLabel={getLocalizedString("clear")}
                        cancelLabel={getLocalizedString("cancel")}
                        okLabel={getLocalizedString("ok")}
                        disablePast
                        format="dd/MM/yyyy"
                        margin="normal"
                        name="dateIni"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid container item xs={6} sm={3} style={{ paddingRight: shouldApplyMediaQuery ? 0 : 15 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <TimePicker
                        label={getLocalizedString("hourFrom")}
                        clearable
                        clearLabel={getLocalizedString("clear")}
                        cancelLabel={getLocalizedString("cancel")}
                        okLabel={getLocalizedString("ok")}
                        disablePast
                        format="HH:mm"
                        margin="normal"
                        name="hourIni"
                        ampm={false}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid container item xs={6} sm={3} style={{ paddingRight: 15 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <DatePicker
                        label={getLocalizedString("dateEnd")}
                        clearable
                        clearLabel={getLocalizedString("clear")}
                        cancelLabel={getLocalizedString("cancel")}
                        okLabel={getLocalizedString("ok")}
                        disablePast
                        format="dd/MM/yyyy"
                        margin="normal"
                        name="dateEnd"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid container item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <TimePicker
                        label={getLocalizedString("hourEnd")}
                        clearable
                        clearLabel={getLocalizedString("clear")}
                        cancelLabel={getLocalizedString("cancel")}
                        okLabel={getLocalizedString("ok")}
                        disablePast
                        minDate={values.hourIni}
                        format="HH:mm"
                        margin="normal"
                        name="hourEnd"
                        ampm={false}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
                  <ButtonDialogAction isAccept type="submit" text={getLocalizedString("agree")} />
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
}
export default connect()(SignerContent);

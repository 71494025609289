import * as actionTypes from "./actionTypes";

const initialState = {
  data: [],
  concepts: [],
  functions: [],
  types: [],
  groups: [],
  executionList: [],
  pathList: [],
  tableList: [],
  sysTableList: [],
  userMenuList: [],
  queryList: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        concepts: action.payload.concepts,
        functions: action.payload.functions,
        groups: action.payload.groups,
        types: action.payload.types,
        executionList: action.payload.executionList,
        pathList: action.payload.pathList,
        tableList: action.payload.tableList,
        sysTableList: action.payload.sysTableList,
        userMenuList: action.payload.userMenuList,
        queryList: action.payload.queryList,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { deleteSpecialProcessDefinitionParameterAction, duplicateSpecialProcessDefinitionParameterAction } from "../actions";
import { getAlertIsActive } from "../selectors";

const useABM = () => {

  const dispatch = useDispatch();
  const alertIsActive = useSelector(getAlertIsActive);

  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [createEditDialogIsOpen, setCreateEditDialogIsOpen] = useState(false);
  const [codeToDelete, setCodeToDelete] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenCreateDialog = () => {
    if (alertIsActive) {
      dispatch(openSnackbarAction({
        msg: "No es posible crear parámetros porque el proceso especial está asociado con una alerta o aviso del usuario",
        severity: "warning"
      }));
      return;
    }

    setCreateEditDialogIsOpen(true);
  }

  const handleOpenDuplicateDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setDuplicateDialogIsOpen(true);
    setSelectedItem(selectedRows[0]);
  };

  const handleCloseDuplicateDialog = () => {
    setDuplicateDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenEditDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setCreateEditDialogIsOpen(true);
    setSelectedItem(selectedRows[0]);
  };

  const handleCloseEditDialog = () => {
    setCreateEditDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenDeleteDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setDeleteDialogIsOpen(true);
    setCodeToDelete(selectedRows.map(item => {
      return (
        {
          queryCode: item.queryCode,
          paramNum: item.paramNum
        }
      )
    })[0]);
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogIsOpen(false);
    setCodeToDelete(null);
  }

  const handleDelete = () => {
    dispatch(deleteSpecialProcessDefinitionParameterAction(codeToDelete))
      .then(() => {
        handleCloseDeleteDialog();
      })
  }

  const handleDuplicate = (data) => {
    dispatch(duplicateSpecialProcessDefinitionParameterAction(data))
      .then(() => {
        handleCloseDuplicateDialog();
      })
  }

  return {
    deleteDialogIsOpen,
    createEditDialogIsOpen,
    duplicateDialogIsOpen,
    selectedItem,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleOpenDuplicateDialog,
    handleCloseDuplicateDialog,
    handleDelete,
    handleDuplicate
  }
}

export default useABM;

import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("state"),
            field: "statusDoc",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("signDate"),
            field: "fechaDoc",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("signTime"),
            field: "horaDoc",
            filter: "agTextColumnFilter",
            valueGetter: function (params) {
                return (params.data && (params.data["horaDoc"] || "")) || "";
            },
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("signDocType"),
            field: "U_tipodoc",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("signDocCod"),
            field: "U_idcod",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("signDocName"),
            field: "U_docName",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("signRef"),
            field: "U_codref",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("signerCode"),
            field: "U_idsigner",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("signerFullName"),
            field: "signerName",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("signerCodeTo"),
            field: "destinatary",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("signerToFullName"),
            field: "destinataryName",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("dateApprobation"),
            field: "approvationDate",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.signedDocuments],
        config: {
            headerName: getLocalizedString("timeApprobation"),
            field: "approvationTime",
            filter: "agTextColumnFilter",
        }
    },
]
import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, parameterCode, paramNumToUse, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {


    const getInitialFieldData = (fieldName) => {
      if (fieldName === "required") {
        return isCreate ? "Y" : data[fieldName];
      }

      if (fieldName === "queryCode") {
        return parameterCode;
      }

      if (fieldName === "paramNum") {
        return isCreate ? paramNumToUse : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "queryCode",
      "name",
      "required",
      "paramNum",
      "paramType",
      "listType",
      "sql",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const schema = yup.object().shape({
      name: yup.string().max(254).required(),
      required: yup.string().required(),
      sql: yup.string().when('listType', {
        is: (value) => value !== '-',
        then: yup.string().required(),
      }),
      paramType: yup.string().required(),
      listType:  yup.string().required(),
      paramNum: yup.string().max(3).required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }


  const submit = async () => {
    if (await dataIsValid()) {
      const formDataToSend = { ...formData };

      formDataToSend.paramNum = parseInt(formDataToSend.paramNum);
      isCreate ? createCallback(formData) : modifyCallback(formData);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if(fieldName === 'paramType' && value === "I"){
      setFormData({
        ...formData,
        paramType: value,
        sql: '',
        listType: '-',
        required: 'Y',
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;

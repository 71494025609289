import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getSpecialProcessExecutionAPI,
  executeSpecialProcessAPI,
  getSpecialProcessQueryAPI,
} from "@icarius-connection/api";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { QUERY, QUERY_FULFILLED, QUERY_REJECTED } from "@icarius-common/gridProcessResultDialog/actionTypes";

export const getSpecialProcessExecutionAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ROWS });
  try {
    let response = await getSpecialProcessExecutionAPI();

    let rows = response.data && response.data.data;
    let dateFormat = response.data && response.data.date_format;

    dispatch({
      type: actionTypes.GET_ROWS_FULFILLED,
      payload: { rows, dateFormat }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const executeSpecialProcessAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.EXECUTE });
  try {
    let response = await executeSpecialProcessAPI(dataToSend);

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.EXECUTE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.EXECUTE_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: 'Ejecución en proceso para el proceso especial seleccionado', severity: "success" }));
    return response;
  } catch (e) {
    if(e.response.data.error === 'INVALID_EXTENSION'){
      dispatch(openSnackbarAction({ msg: 'Archivo de extensión invalida', severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.EXECUTE_REJECTED, payload: e });
  }
};

export const getSpecialProcessQueryAction = (code) => async (dispatch) => {
  dispatch({ type: QUERY });
  try {
    let response = await getSpecialProcessQueryAPI(`?code=${code}`);
    let result = response.data && response.data.result;
    let filters = response.data && response.data.filters;
    let dateFormat = response.data && response.data.date_format;
    let currencyLocalization = response.data && response.data.currency_localization;
    let name = response.data && response.data.name;

    if (!result) {
      dispatch({ type: QUERY_REJECTED });
      dispatch(openSnackbarAction({ msg: 'No se encontró información del proceso', severity: "warning" }));
      return response;
    }

    dispatch({
      type: QUERY_FULFILLED,
      payload: { gridData: { result, filters, dateFormat, currencyLocalization, name }, }
    });
    return response;
  } catch (e) {
    dispatch({ type: QUERY_REJECTED, payload: e });
    if(e.response.data.status === 'INVALID_DATA'){
      dispatch(openSnackbarAction({ msg: 'No existe una consulta asociada a este proceso', severity: "warning" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
  }
};
import NAME from "./constants";

export const DELETE_REQUEST = NAME + "/DELETE_REQUEST";
export const DELETE_REQUEST_REJECTED = NAME + "/DELETE_REQUEST_REJECTED";
export const DELETE_REQUEST_FULFILLED = NAME + "/DELETE_REQUEST_FULFILLED";
export const DELETE_SIGNER_NOT_CREATED = NAME + "/DELETE_SIGNER_NOT_CREATED";
export const ADD_SIGNER_REJECTED = NAME + "/ADD_SIGNER_REJECTED";
export const ADD_SIGNER_FULFILLED = NAME + "/ADD_SIGNER_FULFILLED";
export const ADD_SIGNER = NAME + "/ADD_SIGNER";
export const GET_SIGNERS = NAME + "/GET_SIGNERS";
export const GET_SIGNERS_FULFILLED = NAME + "/GET_SIGNERS_FULFILLED";
export const GET_SIGNERS_REJECTED = NAME + "/GET_SIGNERS_REJECTED";
export const NEW_SIGNER = NAME + "/NEW_SIGNER";
export const EDIT_SIGNER_REJECTED = NAME + "/EDIT_SIGNER_REJECTED";
export const EDIT_SIGNER_FULFILLED = NAME + "/EDIT_SIGNER_FULFILLED";
export const EDIT_SIGNER = NAME + "/EDIT_SIGNER";
export const GET_ACTIVE_TYPE_DOCS_TO_SIGN_REJECTED = NAME + "/GET_ACTIVE_TYPE_DOCS_TO_SIGN_REJECTED";
export const GET_ACTIVE_TYPE_DOCS_TO_SIGN = NAME + "/GET_ACTIVE_TYPE_DOCS_TO_SIGN";
export const GET_ACTIVE_TYPE_DOCS_TO_SIGN_FULFILLED = NAME + "/GET_ACTIVE_TYPE_DOCS_TO_SIGN_FULFILLED";
export const GET_MANAGERS_EMPLOYERS = NAME + "/GET_MANAGERS_EMPLOYERS";
export const GET_MANAGERS_EMPLOYERS_REJECTED = NAME + "/GET_MANAGERS_EMPLOYERS_REJECTED";
export const GET_MANAGERS_EMPLOYERS_FULFILLED = NAME + "/GET_MANAGERS_EMPLOYERS_FULFILLED";
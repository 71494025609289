import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getSpecialProcessDefinitionParametersAPI,
  createSpecialProcessDefinitionParameterAPI,
  modifySpecialProcessDefinitionParameterAPI,
  deleteSpecialProcessDefinitionParameterAPI,
  duplicateSpecialProcessDefinitionParameterAPI,
} from "@icarius-connection/api";
import { getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = "No puede existir más de un parámetro que comparta el mismo código o nombre";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getSpecialProcessDefinitionParametersAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ROWS });
  try {
    let response = await getSpecialProcessDefinitionParametersAPI(`?queryCode=${code}`);
    
    let rows = response.data && response.data.data;
    let paramTypes = response.data && response.data.paramTypes;
    let listTypes = response.data && response.data.listTypes;
    let requiredTypes = response.data && response.data.requiredTypes;
    let dateFormat = response.data && response.data.dateFormat;
    let alertActive = response.data && response.data.alertActive;

    dispatch({
      type: actionTypes.GET_ROWS_FULFILLED,
      payload: { rows, paramTypes, listTypes, requiredTypes, dateFormat, alertActive }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createSpecialProcessDefinitionParameterAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createSpecialProcessDefinitionParameterAPI(data);

    let rows = response.data && response.data.data;
    let paramTypes = response.data && response.data.paramTypes;
    let listTypes = response.data && response.data.listTypes;
    let requiredTypes = response.data && response.data.requiredTypes;
    let dateFormat = response.data && response.data.dateFormat;
    let alertActive = response.data && response.data.alertActive;

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { rows, paramTypes, listTypes, requiredTypes, dateFormat, alertActive }
    });
    dispatch(openSnackbarAction({ msg: "Parámetro creado con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifySpecialProcessDefinitionParameterAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifySpecialProcessDefinitionParameterAPI(data);

    let rows = response.data && response.data.data;
    let paramTypes = response.data && response.data.paramTypes;
    let listTypes = response.data && response.data.listTypes;
    let requiredTypes = response.data && response.data.requiredTypes;
    let dateFormat = response.data && response.data.dateFormat;
    let alertActive = response.data && response.data.alertActive;

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { rows, paramTypes, listTypes, requiredTypes, dateFormat, alertActive }
    });
    dispatch(openSnackbarAction({ msg: "Parámetro modificado con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteSpecialProcessDefinitionParameterAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteSpecialProcessDefinitionParameterAPI(data);

    let rows = response.data && response.data.data;
    let paramTypes = response.data && response.data.paramTypes;
    let listTypes = response.data && response.data.listTypes;
    let requiredTypes = response.data && response.data.requiredTypes;
    let dateFormat = response.data && response.data.dateFormat;
    let alertActive = response.data && response.data.alertActive;

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { rows, paramTypes, listTypes, requiredTypes, dateFormat, alertActive }
    });
    dispatch(openSnackbarAction({ msg: "Parámetro eliminado con éxito", severity: "success" }));
    return response;
  } catch (e) {    
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    errorHandler(e, dispatch);
  }
};

export const duplicateSpecialProcessDefinitionParameterAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicateSpecialProcessDefinitionParameterAPI(data);

    let rows = response.data && response.data.data;
    let paramTypes = response.data && response.data.paramTypes;
    let listTypes = response.data && response.data.listTypes;
    let requiredTypes = response.data && response.data.requiredTypes;
    let dateFormat = response.data && response.data.dateFormat;
    let alertActive = response.data && response.data.alertActive;

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: { rows, paramTypes, listTypes, requiredTypes, dateFormat, alertActive }
    });
    dispatch(openSnackbarAction({ msg: "Parámetro duplicado con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};


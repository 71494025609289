import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoading, getData, getUserList } from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  getSocialBlacklistAction,
  deleteSocialBlacklistAction,
  createSocialBlacklistAction,
} from "../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { getUserData } from "src/app/selectors";

const useSocialBlacklist = (groupCode) => {

  const [userSelectionDialogIsOpen, setUserSelectionDialogIsOpen] = useState(false);

  const dispatch = useDispatch();

  const userData = useSelector(getUserData);

  const isLoading = useSelector(getIsLoading);
  const data = useSelector(getData);
  const userList = useSelector(getUserList);

  const formattedUserList = userList.map((item) => ({ ...item, key: item.code }));

  useEffect(() => {
    dispatch(getSocialBlacklistAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, groupCode])

  const handleOpenDeleteDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un usuario', severity: "warning" }));
      return;
    }

    const selectedUser = selectedRows[0];

    if (selectedUser.code === userData.code) {
      dispatch(openSnackbarAction({ msg: 'No puede seleccionarse a si mismo', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: `¿Desea desbloquear a ${selectedRows[0].name}?`,
      onConfirm: () => dispatch(deleteSocialBlacklistAction({ code: selectedRows[0].code })),
    }));
  }

  const handleOpenUserSelectionDialog = () => {
    setUserSelectionDialogIsOpen(true);
  }

  const handleCloseUserSelectionDialog = () => {
    setUserSelectionDialogIsOpen(false);
  }

  const handleBlockUser = (code) => {
    dispatch(createSocialBlacklistAction({ code }))
  }

  const state = {
    userSelectionDialogIsOpen,
  }

  const handlers = {
    handleOpenUserSelectionDialog,
    handleCloseUserSelectionDialog,
    handleBlockUser,
    handleOpenDeleteDialog,
  }

  return {
    isLoading,
    userList: formattedUserList,
    data,
    state,
    handlers,
  }
}

export default useSocialBlacklist;
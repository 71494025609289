import * as actionTypes from "./actionTypes";

const initialState = {
  rows: [],
  dateFormat: "yyyy/mm/dd",
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_STATE:
      return initialState;

    case actionTypes.GET_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.GET_ROWS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.EXECUTE:
      return { ...state, isLoading: true };
    case actionTypes.EXECUTE_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.EXECUTE_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}

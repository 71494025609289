import React, { useEffect, useRef, useState } from "react";
import SpecialProcessDefinition from "./specialProcessDefinition";
import { SpecialProcessDefinitionParameters } from "@icarius-pages/specialProcessDefinitionParameters";
import paths from "@icarius-localization/paths";

const SpecialProcessDefinitionSwitch = ({ history, location }) => {

  const locationState = useRef(location?.state);
  const hasSetUserFromLocation = useRef(false);

  // para determinar a que pantalla hacer el goBack y limpiar el state
  useEffect(() => {
    if (locationState.current && !Boolean(hasSetUserFromLocation.current)) {
      history.push({
        pathname: paths.specialProcessDefinition,
        state: null,
      });

      hasSetUserFromLocation.current = true;
    }
  }, [history])

  const [parametersIsOpen, setParametersIsOpen] = useState(false);
  const [parameter, setParameter] = useState(null);

  const handleOpenParameterPage = (process) => {
    setParameter(process);
    setParametersIsOpen(true);
  }

  const handleGoBack = () => {
    history.push(locationState.current?.from || paths.extensibilityScreenSelector);
  }

  return (
    <>
      {
        parametersIsOpen ?
          <SpecialProcessDefinitionParameters
            parameter={parameter}
            handleGoBack={() => setParametersIsOpen(false)}
          />
          :
          <SpecialProcessDefinition
            handleGoBack={handleGoBack}
            handleOpenParameterPage={handleOpenParameterPage}
          />
      }
    </>
  );
}

export default SpecialProcessDefinitionSwitch;

import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { createSpecialProcessDefinitionAction, modifySpecialProcessDefinitionAction } from "../../actions";
import { useDispatch } from "react-redux";

const gridStyle = { height: 75, padding: "0px 10px" };
const flexStyle = { width: '100%', height: 50, padding: "0px 10px", display: "flex", alignItems: "center", cursor: "pointer" };

const CreateEditDialog = (props) => {

  const {
    open,
    loading,
    data,
    concepts,
    functions,
    types,
    groups,
    executionList,
    pathList,
    tableList,
    sysTableList,
    userMenuList,
    queryList,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createSpecialProcessDefinitionAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifySpecialProcessDefinitionAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const { isCreate, formData, setFormValue, submit } = useHandleForm(data, create, modify, openValidationError);

  if (loading) return null;

  const getGridArrayToUse = () => {
    if (formData.execution === "GCO") return pathList;
    if (formData.execution === "UST") return tableList;
    if (formData.execution === "SYT") return sysTableList;
    if (formData.execution === "MNU") return userMenuList;
    if (formData.execution === "CON") return queryList;
    return [];
  }

  const getGridLabel = () => {
    if (formData.execution === "GCO") return "Grilla asociada";
    if (formData.execution === "UST") return "Tabla de usuario asociada";
    if (formData.execution === "SYT") return "Tabla de sistema asociada";
    if (formData.execution === "MNU") return "Menú de usuario";
    if (formData.execution === "CON") return "Consulta asociada";
    return '';
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear proceso especial" : "Editar proceso especial"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* codigo */}
              <TextField
                disabled={!Boolean(isCreate)}
                required
                fullWidth
                margin={"none"}
                label={'Código del proceso'}
                onChange={(e) => setFormValue(e.target.value, "code")}
                value={formData.code}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
              <TextField
                required
                fullWidth
                margin={"none"}
                label={'Nombre del proceso'}
                value={formData.name}
                onChange={(e) => setFormValue(e.target.value, "name")}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* group */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-group`}>{"Grupo"}</InputLabel>
                <Select
                  value={formData.group}
                  labelId={`label-group`}
                  id={`select-group`}
                  onChange={(e) => setFormValue(e.target.value, "group")}
                  margin={"none"}
                >
                  {
                    groups && groups.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* tipo */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-type`}>{"Tipo de proceso"}</InputLabel>
                <Select
                  value={formData.type}
                  labelId={`label-type`}
                  id={`select-type`}
                  onChange={(e) => setFormValue(e.target.value, "type")}
                  margin={"none"}
                >
                  {
                    types && types.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* function start */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-functionStart`}>{"Función al iniciar"}</InputLabel>
                <Select
                  value={formData.functionStart}
                  labelId={`label-functionStart`}
                  id={`select-functionStart`}
                  onChange={(e) => setFormValue(e.target.value, "functionStart")}
                  margin={"none"}
                >
                  <MenuItem className={"whiteText"} value={''}>{''}</MenuItem>
                  {
                    functions && functions.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* concept */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-concept`}>{"Concepto para procesar"}</InputLabel>
                <Select
                  value={formData.concept}
                  labelId={`label-concept`}
                  id={`select-concept`}
                  onChange={(e) => setFormValue(e.target.value, "concept")}
                  margin={"none"}
                >
                  {
                    concepts && concepts.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* function start */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-functionEnd`}>{"Función al finalizar"}</InputLabel>
                <Select
                  value={formData.functionEnd}
                  labelId={`label-functionEnd`}
                  id={`select-functionEnd`}
                  onChange={(e) => setFormValue(e.target.value, "functionEnd")}
                  margin={"none"}
                >
                  <MenuItem className={"whiteText"} value={''}>{''}</MenuItem>
                  {
                    functions && functions.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* modo de ejecución */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-execution`}>{"Modo de ejecución"}</InputLabel>
                <Select
                  disabled={formData.type === 'CAL'}
                  value={formData.execution}
                  labelId={`label-execution`}
                  id={`select-execution`}
                  onChange={(e) => setFormValue(e.target.value, "execution")}
                  margin={"none"}
                >
                  {
                    executionList && executionList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              (["GCO", "UST", "SYT", "MNU", "CON"].includes(formData.execution)) &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-path`}>{getGridLabel()}</InputLabel>
                  <Select
                    value={formData.grid}
                    labelId={`label-grid`}
                    id={`select-grid`}
                    onChange={(e) => setFormValue(e.target.value, "grid")}
                    margin={"none"}
                  >
                    {
                      getGridArrayToUse()?.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
            <div style={flexStyle} onClick={() => setFormValue(!Boolean(formData.active), "active")}> {/* active */}
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {"Activo"}
              </Typography>
              <Checkbox checked={formData.active} />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog >
  );
}

export default CreateEditDialog;

import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getSpecialProcessQueryAction } from "@icarius-pages/specialProcessExecution/actions";
import { getLoading } from "../../selectors";

const useSpecialProcessQuery = () => {

  const dispatch = useDispatch();
  const isLoading = useSelector(getLoading);

  const handleGetSpecialProcessQuery = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedOneRow"), severity: "warning" }));
      return;
    }

    const process = selectedRows[0];
    const code = process['Código del proceso'] || process.code;
    dispatch(getSpecialProcessQueryAction(code));
  }

  const specialProcessQueryData = {
    isLoading,
  }

  const specialProcessQueryFunctions = {
    handleGetSpecialProcessQuery,
  }

  return {
    specialProcessQueryData,
    specialProcessQueryFunctions,
  }
}

export default useSpecialProcessQuery;

import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useSpecialProcessDefinition from "./useSpecialProcessDefinition";
import CreateEditDialog from "./createEditDialog/index";
import useSpecialProcessQuery from "@icarius-pages/specialProcessExecution/components/hooks/useSpecialProcessQuery";
import ListIcon from '@material-ui/icons/List';
import { useSelector } from "react-redux";
import { getIsAdmin } from "src/app/selectors";

const SpecialProcessDefinition = ({ handleGoBack, handleOpenParameterPage }) => {

  const {
    data,
    concepts,
    functions,
    types,
    groups,
    executionList,
    pathList,
    tableList,
    sysTableList,
    userMenuList,
    queryList,
    isLoading,
    state,
    handlers,
  } = useSpecialProcessDefinition();

  const {
    specialProcessQueryData,
    specialProcessQueryFunctions,
  } = useSpecialProcessQuery();

  const isAdmin = useSelector(getIsAdmin);

  const handleRowClick = (row) => {
    handleOpenParameterPage(row.data);
  }

  const createButton = (gridRef) => isAdmin && (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog(gridRef)}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => isAdmin && (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => isAdmin && (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />
  )

  const queryButton = (gridRef) => (
    <CustomIconButton
      title={'Consulta del proceso especial'}
      onClick={() => specialProcessQueryFunctions.handleGetSpecialProcessQuery(gridRef)}
    >
      <ListIcon />
    </CustomIconButton>
  )

  return (
    <CommonPage
      isLoading={isLoading || specialProcessQueryData.isLoading}
      title={'Definición de procesos especiales'}
      gridTitle={'Definición de procesos especiales'}
      columnDefPage={paths.specialProcessDefinition}
      rowData={data}
      handleRowClick={isAdmin && handleRowClick}
      menuItems={[goBackButton, createButton, modifyButton, deleteButton, queryButton]}
      hasExpand
      hasHelp
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          loading={isLoading}
          data={state.selected}
          concepts={concepts}
          functions={functions}
          types={types}
          groups={groups}
          executionList={executionList}
          pathList={pathList}
          tableList={tableList}
          sysTableList={sysTableList}
          userMenuList={userMenuList}
          queryList={queryList}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
    </CommonPage>
  )
}

export default SpecialProcessDefinition;
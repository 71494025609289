import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSpecialProcessDefinitionParameterAction, modifySpecialProcessDefinitionParameterAction } from "../../actions";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { withStyles } from "@material-ui/core/styles";
import { getParamTypes, getListTypes, getRequiredTypes } from "../../selectors";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const QueryForm = (props) => {

  const { data, parameterCode, paramNumToUse, handleClose, classes } = props;
  const dispatch = useDispatch();

  const paramTypes = useSelector(getParamTypes);
  const listTypes = useSelector(getListTypes);
  const requiredTypes = useSelector(getRequiredTypes);

  const create = (formData) => {
    dispatch(createSpecialProcessDefinitionParameterAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (formData) => {
    dispatch(modifySpecialProcessDefinitionParameterAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, parameterCode, paramNumToUse, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ width: "95%", margin: "auto" }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* codigo */}
          <TextField
            disabled
            fullWidth
            margin={"none"}
            label={"Codigo de la consulta"}
            value={formData.queryCode}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Nombre del parámetro"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Tipo de parámetro */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-param-type`}>{"Tipo de parámetro"}</InputLabel>
            <Select
              value={formData.paramType}
              labelId={`label-param-type`}
              id={`select-param-type`}
              onChange={(e) => setFormValue(e.target.value, "paramType")}
              margin={"none"}
            >
              {
                paramTypes && paramTypes.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* sql */}
          <TextField
            disabled={formData.paramType === 'I'}
            required={formData.listType !== "-"}
            fullWidth
            label="SQL para obtener la lista de valores para el parámetro"
            variant="outlined"
            multiline
            rows={25}
            helperText={
              "Para el SQL es necesario que el primer campo del SELECT se llame \"Code\", el cual debe contener el código del parámetro, y el segundo campo se llame \"Name\", el cual debe contener la descripción del parámetro. Utilice el AS para definir el Alias dentro del SELECT y asignarle a los campos los nombres \"Code\" y \"Name\"."
            }
            margin={"none"}
            value={formData.sql}
            onChange={(e) => setFormValue(e.target.value, "sql")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* listType */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-selector-type`}>{"Tipo de selección de lista"}</InputLabel>
            <Select
              disabled={formData.paramType === 'I'}
              value={formData.listType}
              labelId={`label-selector-type`}
              id={`select-selector-type`}
              onChange={(e) => setFormValue(e.target.value, "listType")}
              margin={"none"}
            >
              {
                listTypes && listTypes.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
            <Typography variant={"caption"} className={"whiteText"} style={{ marginLeft: 14, marginRight: 14 }}>
              {"Tipo de selección de lista solo es válido para parámetros cuya lista de valores se obtenga utilizando el SQL de arriba"}
            </Typography>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* required */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-required`}>{"Parámetro obligatorio"}</InputLabel>
            <Select
              value={formData.required}
              labelId={`label-required`}
              id={`select-required`}
              onChange={(e) => setFormValue(e.target.value, "required")}
              margin={"none"}
            >
              {
                requiredTypes && requiredTypes.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Número de orden del parámetro */}
          <TextField
            disabled
            fullWidth
            margin={"none"}
            label={'Número de orden del parámetro'}
            type="number"
            value={formData.paramNum}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 70 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(QueryForm);

import NAME from "./constants";

export const GET_DOC_ROWS = NAME + "/GET_PEOPLE_ROWS";
export const GET_DOC_ROWS_FULFILLED = NAME + "/GET_DOC_ROWS_FULFILLED";
export const GET_DOC_ROWS_REJECTED = NAME + "/GET_DOC_ROWS_REJECTED";

export const DOWNLOAD_DOC = NAME + "/DOWNLOAD_DOC";
export const DOWNLOAD_DOC_FULFILLED = NAME + "/DOWNLOAD_DOC_FULFILLED";
export const DOWNLOAD_DOC_REJECTED = NAME + "/DOWNLOAD_DOC_REJECTED";

export const DELETE_DOC = NAME + "/DELETE_DOC";
export const DELETE_DOC_FULFILLED = NAME + "/DELETE_DOC_FULFILLED";
export const DELETE_DOC_REJECTED = NAME + "/DELETE_DOC_REJECTED";

export const SEND_MAIL = NAME + "/SEND_MAIL";
export const SEND_MAIL_FULFILLED = NAME + "/SEND_MAIL_FULFILLED";
export const SEND_MAIL_REJECTED = NAME + "/SEND_MAIL_REJECTED";

export const CLEAR_STATE = NAME + "/CLEAR_STATE";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentsSignedFromServer,
  downloadDocumentsFromServer,
  sendMailAction,
  deleteDocumentsFromServer,
} from "../actions";
import {
  getDocRows,
  loading,
  downloading,
  getDateFormat,
  getLocale,
  getDocsFailed,
  getDocsFailedDelete,
} from "../selectors";
import { getAppColor } from "../../../app/selectors";
import { getIsExportingGrid } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid } from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmationDialog from "@icarius-pages/myPeople/components/dialog/confirmationDialog";
import DocsFailedDialog from "./docsFailedDialog";
import ConfirmPinDialog from "./confirmPinDialog";
import { CLEAR_STATE } from "../actionTypes";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const SignedDocuments = () => {

  const dispatch = useDispatch();
  const docRows = useSelector(getDocRows);
  const docsFailed = useSelector(getDocsFailed);
  const docsFailedDelete = useSelector(getDocsFailedDelete);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);
  const loadingStatus = useSelector(loading);
  const downloadingStatus = useSelector(downloading);
  const exportingGridStatus = useSelector(getIsExportingGrid);
  const color = useSelector(getAppColor);

  const [docsFailedDialogIsOpen, setDocsFailedDialogIsOpen] = useState(false);
  const [usingDocsFailed, setUsingDocsFailed] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [confirmPinDialogOpen, setConfirmPinDialogOpen] = useState(false);
  const [documentsPendingOfApproval, setDocumentsPendingOfApproval] = useState(null);
  const [documentsPendingOfRemoval, setDocumentsPendingOfRemoval] = useState(null);

  useEffect(() => {
    dispatch(getDocumentsSignedFromServer());

    return () => {
      dispatch({ type: CLEAR_STATE });
    }
  }, [dispatch])

  useEffect(() => {
    if (docsFailed && docsFailed.length > 0) {
      setDocsFailedDialogIsOpen(true);
      setUsingDocsFailed(false);
    }
  }, [docsFailed])

  useEffect(() => {
    if (docsFailedDelete && docsFailedDelete.length > 0) {
      setDocsFailedDialogIsOpen(true);
      setUsingDocsFailed(true);
    }
  }, [docsFailedDelete])

  const handleDownloadReceipts = (gridRef) => {
    let selectedDocs = [];

    //Con esto obtengo los seleccionados
    gridRef.api.getSelectedRows().map(item => selectedDocs.push(item["U_idcod"]));

    // Si no hay seleccionado, pido seleccionar
    if (selectedDocs.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }
    dispatch(downloadDocumentsFromServer(selectedDocs));
  };

  const handleDeleteReceipts = (gridRef) => {
    let error = 0;

    let selectedDocs = gridRef.api.getSelectedRows()
      .map(doc => (
        {
          docCode: doc["U_idcod"],
          dest: doc["destinatary"],
          status: doc["statusDoc"],
          destinataryName: doc["destinataryName"],
          docName: doc["U_docName"],
        }
      ));

    // Si no hay seleccionado, pido seleccionar
    if (selectedDocs.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    selectedDocs = selectedDocs.map(doc => {
      if (doc.status !== "Aprobación pendiente") {
        dispatch(openSnackbarAction({ msg: getLocalizedString("youCanOnlyDeleteDocumentsWithPendingApproval"), severity: "error" }));
        error = 1;
      } else {
        delete doc.status;
      }

      return doc;
    })

    if (error) {
      return;
    }
    setDocumentsPendingOfRemoval(selectedDocs);
    setConfirmPinDialogOpen(true);
  };

  const handleSendMail = (gridRef) => {

    let selectedRows = gridRef.api.getSelectedRows()
      .filter(doc => doc.statusDoc === "Aprobación pendiente")
      .map(doc =>
      ({
        docCode: doc["U_idcod"],
        dest: doc["destinatary"],
        destinataryName: doc["destinataryName"],
        docName: doc["U_docName"],
      }
      ));

    //Si no hay seleccionado, indico que no hay mail para envilar
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRowAprovalPending"), severity: "warning" }));
      return;
    }

    setConfirmationDialogIsOpen(true);
    setDocumentsPendingOfApproval(selectedRows);
  };

  const handleConfirmMail = () => {
    dispatch(sendMailAction(documentsPendingOfApproval))
  }

  const handleCancelMail = () => {
    setConfirmationDialogIsOpen(false);
  }

  const handleCloseConfirmPinDialogOpen = () => {
    setConfirmPinDialogOpen(false);
  }

  const handleSignButtonClick = (motive, pin) => {
    try {
      dispatch(deleteDocumentsFromServer(documentsPendingOfRemoval, motive, pin)).then(response => response?.status === 200 && dispatch(getDocumentsSignedFromServer()));
      setDocumentsPendingOfRemoval(null)
    } catch (e) {
      switch (e.response?.statusText) {
        case "Unauthorized":
          dispatch(openSnackbarAction({ msg: getLocalizedString("unAuthorized"), severity: "error" }));
          break;
        default:
          dispatch(openSnackbarAction({ msg: getLocalizedString("defaultError"), severity: "error" }));
          break
      }
    }
  }
  const downloadButton = (gridRef) =>
    <Grid
      direction="row"
      container
      item
      xs={12}
      md={12}
      justify="flex-end"
      alignItems="center">
      <CustomIconButton
        title={getLocalizedString("download")}
        onClick={() => handleDownloadReceipts(gridRef)}
        type={"download"}
      />
      <CustomIconButton
        title={getLocalizedString("delete")}
        onClick={() => handleDeleteReceipts(gridRef)}
        type={"delete"}
      />
    </Grid>

  const mailButton = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("sendMessage")}
      onClick={() => handleSendMail(gridRef)}
      type={"mail"}
    />

  const helpItems = [
    <MenuItemWithIcon key={"a"} value={"/resources/ICARIUS - Firma Digital de documentos.pdf"} text={getLocalizedString("digitalSignature")} type={"file"} />,
    <MenuItemWithIcon key={6} value={"https://www.youtube.com/embed/kDrBI7MB0GM"} text={getLocalizedString("digitalSignature")} type={"video"} />,
  ]

  return (
    <>
      <CommonPage
        helpItems={helpItems}
        menuItems={[downloadButton, mailButton]}
        rowData={docRows}
        title={getLocalizedString("signedDocuments")}
        gridTitle={getLocalizedString("signedDocuments")}
        locale={locale}
        dateFormat={dateFormat}
        columnDefPage={paths.signedDocuments}
        isLoading={loadingStatus || exportingGridStatus || downloadingStatus}
        hasExpand
        hasHelp
        codeFieldName={'destinatary'}
      >
        {
          confirmationDialogIsOpen &&
          <ConfirmationDialog
            isJustified={true}
            open={confirmationDialogIsOpen}
            handleConfirm={handleConfirmMail}
            handleClose={handleCancelMail}
            code={documentsPendingOfApproval}
            text={getLocalizedString("mailRequestedDocuments")}
          />
        }
        {
          docsFailedDialogIsOpen &&
          <DocsFailedDialog
            open={docsFailedDialogIsOpen}
            handleClose={() => setDocsFailedDialogIsOpen(false)}
            isDelete={usingDocsFailed}
            data={usingDocsFailed ? docsFailedDelete : docsFailed}
          />
        }
      </CommonPage>
      {
        confirmPinDialogOpen &&
        <ConfirmPinDialog
          open={confirmPinDialogOpen}
          color={color}
          text={getLocalizedString("confirmDeleteRequestedDocuments")}
          onClose={handleCloseConfirmPinDialogOpen}
          handleConfirm={handleSignButtonClick}
        />
      }
    </>
  );
}

export default SignedDocuments;

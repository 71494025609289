import NAME from "./constants";

export const getLoading = store => store[NAME].isLoading;
export const getRows = store => store[NAME].rows;
export const getDateFormat = store => store[NAME].dateFormat;

export const getParamTypes = store => store[NAME].paramTypes;
export const getListTypes = store => store[NAME].listTypes;
export const getRequiredTypes = store => store[NAME].requiredTypes;
export const getAlertIsActive = store => store[NAME].alertActive;

import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if(fieldName === 'active'){
        return isCreate ? true : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'code',
      'name',
      'group',
      'active',
      'concept',
      'functionStart',
      'functionEnd',
      'type',
      'execution',
      'grid',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const codeRegex = /^[A-Z]+[A-Z0-9_]*/;

    const schema = yup.object().shape({
      code: yup.string().matches(codeRegex).max(20).required(),
      name: yup.string().max(100).required(),
      group: yup.string().required(),
      type: yup.string().required(),
      concept: yup.string().max(8).required(),
      functionStart: yup.string().max(20),
      functionEnd: yup.string().max(20),
      execution: yup.string().required(),
      grid: yup.string()
        .when('execution', {
          is: (value) => ["GCO", "UST", "SYT", "MNU", "CON"].includes(value),
          then: yup.string().required()
        }),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
      };

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if(fieldName === "code"){
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }
    
    return value;
  }

  const setFormValue = (value, fieldName) => {
    if(fieldName === 'type' && value === 'CAL'){
      setFormData({
        ...formData,
        [fieldName]: formatValue(value, fieldName),
        execution: 'M',
      })
      return;
    }

    if(fieldName === 'execution'){
      setFormData({
        ...formData,
        [fieldName]: value,
        grid: '',
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName),
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;

import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import useSocialBlacklist from "./useSocialBlacklist";
import UserSelectionDialog from "@icarius-common/userSelectionDialog";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const SocialBlacklist = () => {

  const {
    isLoading,
    data,
    userList,
    state,
    handlers,
  } = useSocialBlacklist();

  const blockButton = () => (
    <CustomIconButton
      title={'Bloquear usuario'}
      onClick={handlers.handleOpenUserSelectionDialog}
    >
      <LockIcon />
    </CustomIconButton>
  )

  const unlockButton = (gridRef) => (
    <CustomIconButton
      title={'Desbloquear usuario'}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
    >
      <LockOpenIcon />
    </CustomIconButton>
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Usuarios bloqueados en Social'}
      gridTitle={'Usuarios bloqueados en Social'}
      columnDefPage={paths.socialBlacklist}
      rowData={data}
      menuItems={[blockButton, unlockButton]}
      hasExpand
      hasHelp
    >
      {
        state.userSelectionDialogIsOpen &&
        <UserSelectionDialog
          open={state.userSelectionDialogIsOpen}
          users={userList}
          title={'Seleccione qué usuario desea bloquear'}
          selectedUsersTitle={'Usuario seleccionado'}
          handleClose={handlers.handleCloseUserSelectionDialog}
          handleSubmit={(data) => handlers.handleBlockUser(data[0])}
          singleSelection
          required
        />
      }
    </CommonPage>
  )
}

export default SocialBlacklist;
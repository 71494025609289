import React from "react";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import CustomTextField from "@icarius-common/input/textField";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { valueIsOfType, valueTypes } from "@icarius-utils/types";
import { loading } from "../selectors";
import { MAX_PIN_LENGTH, MIN_PIN_LENGTH, MIN_MOTIVE_LENGTH, MAX_MOTIVE_LENGTH } from "@icarius-utils/constants";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";

const initialState = {
  pin: "",
  error: "",
  motive: "",
};

class ChangePinDialog extends React.PureComponent {
  state = initialState;
  render() {
    const { open, color, text } = this.props;
    const { pin, motive, error } = this.state;

    return (
      <Dialog
        open={open}
        PaperComponent={PaperDraggable}
        maxWidth={"sm"}
        fullWidth={true}>
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {getLocalizedString("confirmDeleteDocument")}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={this.handleClose} />
          <DialogContent>
            {
              text &&
              <Typography variant={"subtitle1"} className={"whiteText"}>
                {text}
              </Typography>
            }
            <div className={"center-input"}>
              <form noValidate>
                <TextField
                  label={getLocalizedString("deletedDocumentsMotivo")}
                  value={motive}
                  onChange={e => this.handleTextChange("motive", e.target.value)}
                  style={{
                    color: color || "white",
                    borderColor: color || "white",
                    width: "100%",
                  }}
                  inputProps={{
                    autoComplete: 'motive',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  multiline={true}
                  rowsMax={4}
                />

                <div style={{ display: "none" }}>
                  <CustomTextField
                    label={""}
                    value={pin}
                    showState
                    color={color}
                    isValid={valueIsOfType(pin, valueTypes.pin)}
                    type={"password"}
                    onChange={value => this.handleTextChange("pin2", value)}
                    maxWidth={true}
                  />
                </div>

                <div style={{ marginTop: 16 }}>
                  <CustomTextField
                    label={getLocalizedString("pin")}
                    value={pin}
                    showState
                    isValid={valueIsOfType(pin, valueTypes.pin)}
                    type={"password"}
                    onChange={value => this.handleTextChange("pin", value)}
                    maxWidth={true}
                  />
                </div>

              </form>
            </div>
            {
              error ?
                <Typography variant={"subtitle1"} className={"error-text"}>
                  {error}
                </Typography>
                : null
            }
          </DialogContent>
          <DialogActions>
            <ButtonDialogAction
              onClick={this.handleClose}
              text={getLocalizedString("disagree")}
            />
            <ButtonDialogAction
              onClick={this.handleConfirm}
              isAccept
              text={getLocalizedString("agree")}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  handleTextChange = (name, value) => this.setState({ [name]: value, error: "" });
  handleConfirm = () => {
    const { pin, motive } = this.state;
    try {
      this.validateInputs(pin, motive);
      this.props.handleConfirm(motive, pin);
      this.handleClose();
    } catch (e) {
      this.setState({ error: e });
    }
  };

  validateInputs = (pin, motive) => {
    if (!motive) throw getLocalizedErrorString("noMotive");
    if (motive.length < MIN_MOTIVE_LENGTH) throw getLocalizedErrorString("motiveMustHaveMinCharacters");
    if (motive.length > MAX_MOTIVE_LENGTH) throw getLocalizedErrorString("motiveMustHaveMinCharacters");

    if (!pin) throw getLocalizedErrorString("noPin");

    if (pin.length < MIN_PIN_LENGTH) throw getLocalizedErrorString("pinMustHaveMinCharacters");

    if (pin.length > MAX_PIN_LENGTH) throw getLocalizedErrorString("pinMustHaveMaxCharacters");

    if (!valueIsOfType(pin, valueTypes.pin)) throw getLocalizedErrorString("pinMustBeAlphanumeric");
  };

  handleClose = () => {
    this.setState({ ...initialState });
    this.props.onClose && this.props.onClose();
  };
}

export default connect(
  createStructuredSelector({
    loading: loading,
  })
)(ChangePinDialog);
